import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import NewFooter from '../../components/NewFooter'
import { Box, Typography, Grid, Card, useTheme, useMediaQuery, Container, Button } from '@material-ui/core';
import { useLanguage } from '../../hooks/languageContext';
import { MobileSubNavigation, DesktopSubNav } from '../../components/NavComponent';
import MyHealthPackageCard from './MyHealthPackageCard';
import { GetUserHealthPackages } from '../../request';
import { getUserData } from '../../utils/utils';
import ThemeLoader from '../../utils/Loader';

const  MyHealthPackages =() =>{

    const theme = useTheme()
    const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"));
    const { lang, langContent } = useLanguage()
    const [userHealthPackagaes, setuserHealthPackagaes] = React.useState([])
    const [isLoading, setIsLoading] = useState(true)
    const userId = getUserData('user_id')
    useEffect(()=>{

        setIsLoading(true)
        GetUserHealthPackages(setIsLoading,setuserHealthPackagaes,userId);
      },[])
      
    return (
        <div>
            <Header />
            {isLoading  ? <ThemeLoader /> :
            <Container>
                <Box display="flex" justifyContent="center" mt={1} >
                    <Box style={{ width: '100%' }}>
                        <Box display="flex" justifyContent="center" mt={matchesSMDown ? 4 : 10} mb={2}>
                            <Typography variant="h1" >
                                {langContent.my_health_packages}
                            </Typography>
                        </Box>
                        <Box mb={7.2} display="flex" justifyContent="flex-start">
                            <Box style={{ width: '100%' }}>
                                {
                                    matchesSMDown ? <MobileSubNavigation /> : <DesktopSubNav />
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                          {
                            userHealthPackagaes?.length ?
                            <Grid container spacing={3}>
                                {userHealthPackagaes.map((hpackage) => (
                                <Grid item xs={12} sm={6} md={4} key={hpackage.order_id}>
                                    <MyHealthPackageCard hpackage={hpackage} key={hpackage.order_id} />
                                </Grid>
                                ))}
                            </Grid>
                            :
                            <Typography variant="h6" component="h6" color='error' style={{fontSize:'16px',display:'flex',justifyContent:'center'}}>
                             {langContent.no_health_packages_found}
                            </Typography>
                          }
                        </Box>
            </Container>
            }
            <NewFooter />
        </div>
        
    )
}

export default MyHealthPackages
