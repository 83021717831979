import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { Button, Divider } from '@material-ui/core';
import { SHOW_TOAST_MESSAGE_SUCCESS } from '../../utils/utils';
import moment from 'moment/moment';
import { useHistory, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom';
const MyHealthhpackageCard = ({hpackage}) => {
  const history = useHistory()

  const copyText = () =>{
    navigator.clipboard.writeText(hpackage.discount_code)
    SHOW_TOAST_MESSAGE_SUCCESS("Voucher code copied.")
  }

  const handleBookNow = () => {
    const clinicId = hpackage.dr_clinic_id;
    const packageId = hpackage.labtest_id;
    const cartId = hpackage.encrypted_cart_id;
    // const packageId = hpackage.labtest_id;
    history.push(`/confirm_health_package_booking_date/${hpackage.dr_clinic_nice_name}?clinic=${clinicId}&package=${packageId}&cart=${cartId}`);
  };
  
  return (
    <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '150px',
      maxWidth: '100%',
      m: 2,
    }}
    key={hpackage.order_id}
  >
    <CardContent>
      <Grid container>
        <Grid item md={3} style={{ display: 'flex', alignItems: 'center' }}>
          <i
            className="fa-solid fa-ticket"
            style={{
              fontSize: '45px',
              marginRight: '1rem',
              rotate: '145deg',
              color: hpackage.used === 1 ? 'red' : '#17978d',
            }}
          ></i>
          <Divider orientation="vertical" variant="string" />
        </Grid>
        <Grid item md={9} style={{ position: 'relative' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" component="h6" style={{ fontSize: '16px' }}>
              {hpackage.labtest_name}
            </Typography>
          </Box>
          <Typography variant="body2" color="textSecondary">
            Status: {hpackage.order_status === '0' ? 'Paid ' : 'Unpaid'}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Order Date: {moment(hpackage.order_c_date).format('DD MMM YYYY')}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Amount: {hpackage.order_amount} VND
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  
    {/* Conditional Book Now Button */}
    {hpackage.order_status === '0' && (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleBookNow(hpackage)}
        >
          Book Now
        </Button>
      </Box>
    )}
  </Card>
  
  );
};

export default MyHealthhpackageCard;
