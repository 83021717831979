import React from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Typography } from '@material-ui/core'
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import viLocale from "date-fns/locale/vi";
import { useLanguage } from '../../hooks/languageContext';
import ThemeSelectWithSelect from '../formElements/ThemeSelectWithSelect';
import ThemeLoader from '../../utils/Loader';
const BookPackageDialogComponent = ({ isAddPackageDialogueOpen, setIsAddPackageDialogueOpen, submitHandler, onSubmit, selectedDate, register, errors, healthPackageTimeSlots, memberList, setSelectedDate, setValue, name, isLoading }) => {
    const { lang, langContent } = useLanguage()
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setValue('package_date', date, { shouldValidate: true })
    }
    return (

        <Dialog open={isAddPackageDialogueOpen} onClose={() => setIsAddPackageDialogueOpen(false)} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">
                <Typography variant="h6">
                    {langContent.select_patient}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={submitHandler(onSubmit)} noValidate>
                    <FormControl fullWidth style={{ marginBottom: '1.6rem' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={lang === 'vn' ? viLocale : ''}>
                            <DatePicker value={selectedDate ? selectedDate : null} onChange={handleDateChange} minDate={new Date()}
                                label={langContent.collection_date}
                                okLabel={langContent.ok}
                                cancelLabel={langContent.cancel}
                                format="dd/MM/yyyy"
                                name="package_date"
                                inputRef={register}
                                required
                                ref={register}
                                errors={errors}
                                inputVariant='filled'
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    disableUnderline: true, // <== added this
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <FormHelperText
                            style={{ color: 'tomato' }}>{errors['package_date']?.message}
                        </FormHelperText>
                    </FormControl>
                    <FormControl fullWidth style={{ minWidth: 250, marginBottom: '1.6rem' }}>
                        <ThemeSelectWithSelect name='sample_collect_time' variant="outlined" label={langContent.collection_time_slot} register={register} errors={errors} fullWidth optionObj={healthPackageTimeSlots.length && healthPackageTimeSlots} isRequired />
                    </FormControl>
                    <FormControl fullWidth style={{ marginBottom: '1.6rem', minWidth: 250 }} >
                        <ThemeSelectWithSelect name="member_id" variant="outlined" label={langContent.select_member} register={register} errors={errors} fullWidth optionObj={memberList} labelKey='member_fname' valueKey='member_id' required />
                    </FormControl>
                    <DialogActions style={{ marginTop: '1rem', marginBottom: '1rem', display: 'flex', justifyContent: 'center' }}>
                        <Button type="submit" color="primary" disabed={isLoading ? true : false} variant="contained" style={{ color: 'white', width: '50%' }}>
                            {
                                isLoading && <CircularProgress color="primary" size={30} />

                            }
                            {

                                !isLoading && langContent.add_to_cart
                            }
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    )
}
export default BookPackageDialogComponent