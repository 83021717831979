import React, { useEffect } from "react";
import { ThemeProvider } from "@material-ui/core";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Loader from "./utils/Loader";
import theme from "./theme/theme";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./routes/privateRoute";
import { LanguageProvider } from "./hooks/languageContext";
import { getActiveCityList } from "./request";
import "../src/styles/style.css";
import PublicRoute from "./routes/PublicRoute";

// import ChatApp from './Chat';
// import CovidInfo from './screens/covid/CovidInfo';
// import Covid19Testing from './screens/covid/Covid19Testing';
// import CovidSymptoms from './screens/covid/CovidSymptoms';
// import LongCovid from './screens/covid/LongCovid';
// import RefundPolicy from './screens/RefundPolicy';
// import RefundPolicyVn from './screens/RefundPolicyVn';
// import PaymentPolicy from './screens/PaymentPolicy';
// import PaymentPolicyVn from './screens/PaymentPolicyVn';
// import CovidTestingPackage from './screens/covid/TestingPackage';
// import PrivacyPolicyEnglish from './screens/policies/PrivacyPolicyEnglish';
// import CancellationPolicyVn from './screens/policies/CancellationPolicyVn';
// import CancellationPolicyEnglish from './screens/policies/CancellationPolicyEnglish';
// import TermsAndConditionsEnglish from './screens/policies/TermsAndCondEnglish';
// import TermsAndConditionsVn from './screens/policies/TermsAndCondVn';
// import DisclaimerVn from './screens/policies/DisclaimerVn';
// import DisclaimerEnglish from './screens/policies/DisclaimerEnglish';
// import AddNewAddress from './screens/address/AddNewAddress';
// import EditAddress from './screens/address/EditAddress';
// import MyAddresses from './screens/address/MyAddress';
// import Blogs from './screens/blogs/Blogs';
// import Home from './screens/home/Home';
// import DeActivateAccount from './screens/user/DeactivateAccount';
import AppInfo from "./screens/appInfo/AppInfo";
import form1a from "./components/forms/form1a";
import form1b from "./components/forms/form1b";
import UserHomeCareSurvey from "./components/UserHomeCareSurvey";
import UserHomeCareSurveyCompleted from "./components/UserHomeCareSurveyCompleted";
import moment from 'moment';
// import { useIdleTimer } from 'react-idle-timer'
import { useState } from "react";
// import HospitalInfo from './screens/hospital/HospitalInfo';
// import ContactUs from './screens/misc/ContactUs';
// import NewSelectDoctor from './screens/doctor/NewSelectDoctor';
// import Feedback from './screens/feedback/FeedBack';
// import HealthPackageDetails from './screens/healthPackage/HealthPackageDetails';
// import DiagSpecialPackage from './screens/healthPackage/DiagSpecialPackages';
// import DiagPackageDetails from './screens/healthPackage/DiagPackageDetails';
// import WalletTransaction from './screens/wallet/WalletTransaction';
// import DoctorDetailsNew from './screens/doctor/DoctorDetailsNew';
// import SomethingWentWrong from './screens/error/SomethingWentWrong';
// import BookHealthPackage from './screens/healthPackage/BookHealthPackage';
// import PageNotFound404 from './screens/error/404';
// import Suggestions from './screens/suggestions/Suggestions';
// import { useIdleTimer } from 'react-idle-timer/legacy'
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from 'react-router-dom';
import UserIdleTimer from "./components/UserIdleTime";
import MedicalHistoryForm from "./components/forms/MedicalHisyoryForm";
import ProductList from "./screens/products/ProductList";
import ProductDetail from "./screens/products/ProductDetail";
import HealthPackageSale from "./screens/healthPackageSale/HealthPackageSale";
import MyHealthPackages from "./screens/myHealthPackages/MyHealthPackages";

const SomethingWentWrong = React.lazy(() =>
  import("./screens/error/SomethingWentWrong")
);
const PageNotFound404 = React.lazy(() => import("./screens/error/404"));
const ChatApp = React.lazy(() => import("./Chat"));
const CovidInfo = React.lazy(() => import("./screens/covid/CovidInfo"));
const Covid19Testing = React.lazy(() =>
  import("./screens/covid/Covid19Testing")
);
const CovidSymptoms = React.lazy(() => import("./screens/covid/CovidSymptoms"));
const LongCovid = React.lazy(() => import("./screens/covid/LongCovid"));
const RefundPolicy = React.lazy(() => import("./screens/RefundPolicy"));
const RefundPolicyVn = React.lazy(() => import("./screens/RefundPolicyVn"));
const PaymentPolicy = React.lazy(() => import("./screens/PaymentPolicy"));
const PaymentPolicyVn = React.lazy(() => import("./screens/PaymentPolicyVn"));
const CovidTestingPackage = React.lazy(() =>
  import("./screens/covid/TestingPackage")
);
const PrivacyPolicyEnglish = React.lazy(() =>
  import("./screens/policies/PrivacyPolicyEnglish")
);
const CancellationPolicyVn = React.lazy(() =>
  import("./screens/policies/CancellationPolicyVn")
);
const CancellationPolicyEnglish = React.lazy(() =>
  import("./screens/policies/CancellationPolicyEnglish")
);
const TermsAndConditionsEnglish = React.lazy(() =>
  import("./screens/policies/TermsAndCondEnglish")
);
const TermsAndConditionsVn = React.lazy(() =>
  import("./screens/policies/TermsAndCondVn")
);
const DisclaimerVn = React.lazy(() =>
  import("./screens/policies/DisclaimerVn")
);
const DisclaimerEnglish = React.lazy(() =>
  import("./screens/policies/DisclaimerEnglish")
);
const AddNewAddress = React.lazy(() =>
  import("./screens/address/AddNewAddress")
);
const GuestInfo = React.lazy(() =>
  import("./screens/address/GuestInfo")
);
const Notifications = React.lazy(() =>
  import("./screens/notification/Notifications")
);
const MedicalHistory = React.lazy(() =>
  import("./screens/member/MedicalHistory") 
);
const MyForms = React.lazy(() =>
  import("./screens/member/MyForms") 
);
const MyVouchers = React.lazy(() =>
  import("./screens/vouchers/MyVoucher") 
);
const EditAddress = React.lazy(() => import("./screens/address/EditAddress"));
const MyAddresses = React.lazy(() => import("./screens/address/MyAddress"));
const Blogs = React.lazy(() => import("./screens/blogs/Blogs"));
const Home = React.lazy(() => import("./screens/home/Home"));
const Newhome = React.lazy(() => import("./screens/home/Newhome"));
const DeActivateAccount = React.lazy(() =>
  import("./screens/user/DeactivateAccount")
);
const HospitalInfo = React.lazy(() =>
  import("./screens/hospital/HospitalInfo")
);
const NewSelectDoctor = React.lazy(() =>
  import("./screens/doctor/NewSelectDoctor")
);
const ContactUs = React.lazy(() => import("./screens/misc/ContactUs"));
const Feedback = React.lazy(() => import("./screens/feedback/FeedBack"));
const HealthPackageDetailsNew = React.lazy(() =>
  import("./screens/healthPackage/HealthPackageDetailsNew")
);
const DiagSpecialPackage = React.lazy(() =>
  import("./screens/healthPackage/DiagSpecialPackages")
);
const DiagPackageDetails = React.lazy(() =>
  import("./screens/healthPackage/DiagPackageDetails")
);
const WalletTransaction = React.lazy(() =>
  import("./screens/wallet/WalletTransaction")
);
const DoctorDetailsNew = React.lazy(() =>
  import("./screens/doctor/DoctorDetailsNew")
);
const BookHealthPackage = React.lazy(() =>
  import("./screens/healthPackage/BookHealthPackage")
);
const Suggestions = React.lazy(() =>
  import("./screens/suggestions/Suggestions")
);
const ViewBlog = React.lazy(() => import("./screens/blogs/ViewBlog"));
const Login = React.lazy(() => import("./screens/auth/Login"));
const ForgotPassword = React.lazy(() => import("./screens/user/ForgotPassword"));
const Logout = React.lazy(() => import("./screens/auth/Logout"));
const HealthPackageList = React.lazy(() =>
  import("./screens/healthPackage/HealthPackageList")
);
const IndividualHosptialMap = React.lazy(() =>
  import("../src/screens/maps/IndividualHospitalMap")
);
const SearchResult = React.lazy(() => import("./screens/search/SearchResult"));
const MembersList = React.lazy(() => import("./screens/member/MemberList"));
const MemberDetails = React.lazy(() =>
  import("./screens/member/MemberDetails")
);
const MedicalHistoryDetails = React.lazy(() =>
  import("./screens/member/MedicalHistoryDetails")
);
const BookAppointment = React.lazy(() =>
  import("./screens/appointment/BookAppointment")
);
const Cart = React.lazy(() => import("./screens/cart/Cart"));
const ShippingAddress = React.lazy(() =>
  import("./screens/payment/ShippingAddress")
);
const OrderConfirmation = React.lazy(() =>
  import("./screens/cart/OrderConfirmation")
);
const Orders = React.lazy(() => import("./screens/orders/MyOrders"));
const OrderDetails = React.lazy(() => import("./screens/orders/OrderDetails"));
const Thankyou = React.lazy(() => import("./screens/orders/Thankyou"));
const Error = React.lazy(() => import("./screens/orders/Error"));
const HealthReport = React.lazy(() =>
  import("./screens/reports/HealthReports")
);
const Consultations = React.lazy(() =>
  import("./screens/consultation/Consultations")
);
const HospitalList = React.lazy(() =>
  import("./screens/hospital/HospitalList")
);
const UpcomingAppointments = React.lazy(() =>
  import("./screens/appointment/UpcomingAppointments")
);
const PastAppointments = React.lazy(() =>
  import("./screens/appointment/PastAppointments")
);
const UserProfile = React.lazy(() => import("./screens/user/Profile"));
const ChangePassword = React.lazy(() => import("./screens/user/ChangePassword"));
const ResetPassword = React.lazy(() => import("./screens/user/ResetPassword"));
const PartnerWithUs = React.lazy(() => import("./screens/misc/PartnerWithUs"));
const AboutUs = React.lazy(() => import("./screens/misc/AboutUs"));
const BookCovidPackage = React.lazy(() =>
  import("./screens/covid/BookCovidPackage")
);
const SelectDoctor = React.lazy(() => import("./screens/doctor/SelectDoctor"));
const AddMember = React.lazy(() => import("./screens/member/AddMember"));
const EditMember = React.lazy(() => import("./screens/member/EditMember"));
const EditProfile = React.lazy(() => import("./screens/user/EditProfile"));
const SearchByAllType = React.lazy(() =>
  import("./screens/search/SearchByAllType")
);
const GoogleMapMultipleHospitals = React.lazy(() =>
  import("./screens/maps/GoogleMapMultipleHospitals")
);
const UploadMedicalHistory = React.lazy(() =>
  import("./screens/member/UploadMedicalHistory")
);
const UploadDocuments = React.lazy(() =>
  import("./screens/member/UploadDocuments")
);

const OtpValidation = React.lazy(() => import("./screens/user/OtpValidation"));
const ValidateForgotPasswordOTP = React.lazy(() => import("./screens/user/ValidateForgotPasswordOTP"));
const PrivacyPolicy = React.lazy(() => import("./screens/PrivacyPolicy"));
const OperationPolicy = React.lazy(() => import("./screens/OperationPolicy"));
const CartPage = React.lazy(() => import("./screens/cart/NewCartPage"));
const BankTransferDetails = React.lazy(() => import("./screens/BankTransferDetails"));
const CorporatePackages = React.lazy(() =>
  import("./components/corporate/packages")
);
const SurveyForm = React.lazy(() => import("./screens/surveyForm/SurveyForm"));
const SurveyFormCompleted = React.lazy(() =>
  import("./components/SurveyFormCompleted")
);

function App() {
  const history = useHistory()

  const [state, setState] = useState('Active')
  const [count, setCount] = useState(0)
  const [remaining, setRemaining] = useState(0)
  const [isLoggedin,setIsLoggedIn] = useState(localStorage?.getItem('loginStatus'))
  // const { start, pause } = useIdleTimer({ startManually: true })
  useEffect(() => {
    getActiveCityList();
  }, []);

  useEffect(()=>{
    // let date_time = localStorage.getItem("login_date_time")
    // console.log(date_time);

  },[state])

  const notificationController =() =>{
      const notification_type = localStorage.getItem('notification_type');
      const member_id = localStorage.getItem('member_id');
      if(notification_type ==1 || notification_type =='1'){
        window.location.href = '#/upload_medical_history?member='+member_id
      }else if( notification_type==0  || notification_type=='0'){
        window.location.href = '#/upcoming_appointments'

      }

  }
 
  // useEffect(() => {
   
  //     const interval = setInterval(() => {
  //       console.log(localStorage.getItem('loginStatus'));
  //       if(localStorage.getItem('loginStatus')=='false')
  //       clearInterval(interval);
  //       setRemaining(Math.ceil(getRemainingTime() / 1000))
  //     }, 500)
  
  //     return () => {
  //       clearInterval(interval)
  //     }
  // });

  // useEffect(()=>{
  //   console.log("Remaiining",remaining);
  // },[remaining])

 
  return (
    <>
      <button style={{display:'none'}} type="button" id="notificationController" onClick={notificationController}></button>
      <Router>
        <React.Suspense fallback={<Loader />}>
          <ThemeProvider theme={theme}>
            <LanguageProvider>
              <Switch>
                {/*
                 * ! Open Route ie. can be Accessed By Both Authenticated and Un-Authenticated User
                 */}
                <Route
                  exact
                  path="/"
                  name="Home"
                  render={(props) => <Newhome {...props} />}
                />
                <Route
                  exact
                  path="/newhome"
                  name="newhome"
                  render={(props) => <Newhome {...props} />}
                />
                <Route
                  exact
                  path="/blog/:id/:lang"
                  name="Blog View"
                  render={(props) => <ViewBlog {...props} />}
                />
                <Route
                  exact
                  path="/cart"
                  name="Cart"
                  render={(props) => <Cart {...props} />}
                  // LoadComponent={Cart}
                />
                {/* <Route exact path="/package_details" name="Package Details" render={props => <PackageDetails {...props} />} /> */}
                {/* <Route exact path="/hospital_details" name="Hospital Details" render={props => <HospitalDetails {...props} />} /> */}
                {/* <Route exact path="/health_packages" name="Health Packages" render={props => <HealthPackageList {...props} />} /> */}
                <Route
                  exact
                  path="/clinic/:clinicName/health_checkup"
                  name="Health Packages"
                  render={(props) => <HealthPackageList {...props} />}
                />
                <Route
                  exact
                  path="/products"
                  name="Products"
                  render={(props) => <ProductList {...props} />}
                />
                <Route
                  exact
                  path="/product_detail"
                  name="Product Detail"
                  render={(props) => <ProductDetail {...props} />}
                />
                <Route
                  exact
                  path="/bank_transfer_details"
                  name="Bank Transfer Detail"
                  render={(props) => <BankTransferDetails {...props} />}
                />
                <Route
                  exact
                  path="/hospital_map"
                  name="Hospital Map View"
                  render={(props) => <IndividualHosptialMap {...props} />}
                />
                {/* <Route exact path="/doctor_details" name="Doctor Details" render={props => <DoctorDetails {...props} />} /> */}
                <Route
                  exact
                  path="/members"
                  name="Members List"
                  render={(props) => <MembersList {...props} />}
                />
                <Route
                  exact
                  path="/hospitals"
                  name="Hospital List"
                  render={(props) => <HospitalList {...props} />}
                />
                <Route
                  exact
                  path="/partner_with_us"
                  name="Partner With Us"
                  render={(props) => <PartnerWithUs {...props} />}
                />
                <Route
                  exact
                  path="/about_us"
                  name="About Us"
                  render={(props) => <AboutUs {...props} />}
                />
                <Route
                  exact
                  path="/user_home_care_survey/:userid"
                  name="User Home Care Survey"
                  render={(props) => <UserHomeCareSurvey {...props} />}
                />
                <Route
                  exact
                  path="/user_home_care_survey_completed/:completed"
                  name="User Home Care Survey"
                  render={(props) => <UserHomeCareSurveyCompleted {...props} />}
                />
                <Route
                  exact
                  path="/select_doctor"
                  name="Select Doctor"
                  render={(props) => <SelectDoctor {...props} />}
                />
                <Route
                  exact
                  path="/search"
                  name="Search Result"
                  render={(props) => <SearchResult {...props} />}
                />
                <Route
                  exact
                  path="/search_type"
                  name="Search Result By All Type"
                  render={(props) => <SearchByAllType {...props} />}
                />
                <Route
                  exact
                  path="/google_map"
                  name="Google Map Multiple Hospitals"
                  render={(props) => <GoogleMapMultipleHospitals {...props} />}
                />
                <Route
                  exact
                  path="/privacy_policy/vn"
                  name="Privacy Policy"
                  render={(props) => <PrivacyPolicy {...props} />}
                />
                <Route
                  exact
                  path="/privacy_policy/en"
                  name="Privacy Policy English"
                  render={(props) => <PrivacyPolicyEnglish {...props} />}
                />
                <Route
                  exact
                  path="/operation_policy"
                  name="Operation Policy"
                  render={(props) => <OperationPolicy {...props} />}
                />
                <Route
                  exact
                  path="/cancellation_policy/en"
                  name="Cancellation Policy"
                  render={(props) => <CancellationPolicyEnglish {...props} />}
                />
                <Route
                  exact
                  path="/cancellation_policy/vn"
                  name="Cancellation Policy"
                  render={(props) => <CancellationPolicyVn {...props} />}
                />
                <Route
                  exact
                  path="/terms_and_conditions/en"
                  name="Terms and conditions EN"
                  render={(props) => <TermsAndConditionsEnglish {...props} />}
                />
                <Route
                  exact
                  path="/terms_and_conditions/vn"
                  name="Terms and conditions VN"
                  render={(props) => <TermsAndConditionsVn {...props} />}
                />
                <Route
                  exact
                  path="/disclaimer/en"
                  name="Terms and conditions VN"
                  render={(props) => <DisclaimerEnglish {...props} />}
                />
                <Route
                  exact
                  path="/disclaimer/vn"
                  name="Terms and conditions VN"
                  render={(props) => <DisclaimerVn {...props} />}
                />
                <Route
                  exact
                  path="/covid_consultation/:id"
                  name="Covid Information"
                  render={(props) => <CovidInfo {...props} />}
                />
                <Route
                  exact
                  path="/refund_policy/en"
                  name="Refund Policy"
                  render={(props) => <RefundPolicy {...props} />}
                />
                <Route
                  exact
                  path="/refund_policy/vn"
                  name="Refund Policy VN"
                  render={(props) => <RefundPolicyVn {...props} />}
                />
                <Route
                  exact
                  path="/payment_policy/en"
                  name="Payment Policy EN"
                  render={(props) => <PaymentPolicy {...props} />}
                />
                <Route
                  exact
                  path="/payment_policy/vn"
                  name="Payment Policy VN"
                  render={(props) => <PaymentPolicyVn {...props} />}
                />
                <Route
                  exact
                  path="/chat"
                  name="User Chat"
                  render={(props) => <ChatApp {...props} />}
                />
                {/* <Route exact path="/new_select_doctor" name="New Select Doctor" render={props => <NewSelectDoctor {...props} />} /> */}
                <Route
                  exact
                  path="/clinic/:clinicName/doctors"
                  name="Hospital's Doctors List"
                  render={(props) => <NewSelectDoctor {...props} />}
                />
                <Route
                  exact
                  path="/diag/:clinicNiceName"
                  name="Diag Special Packages"
                  render={(props) => <DiagSpecialPackage {...props} />}
                />
                {/* <PrivateRoute exact path="/chat" name="User Chat" LoadComponent={ChatApp} /> */}
                {/**
                 * New API System
                 */}
                <Route
                  exact
                  path="/doctorinfo/:id/:id"
                  name="Doctor Details"
                  render={(props) => <DoctorDetailsNew {...props} />}
                />
                <Route
                  exact
                  path="/labtest/:id"
                  name="Package Details"
                  render={(props) => <HealthPackageDetailsNew {...props} />}
                />
                <Route
                  exact
                  path="/diag/labtest/:id"
                  name="Package Details For Diag packages"
                  render={(props) => <DiagPackageDetails {...props} />}
                />
                <Route
                  exact
                  path="/clinic/:id/:id"
                  name="Hospital Details"
                  render={(props) => <HospitalInfo {...props} />}
                />
                <Route
                  exact
                  path="/covidtesting/:id"
                  name="Covid 19 Testing"
                  render={(props) => <Covid19Testing {...props} />}
                />
                <Route
                  exact
                  path="/covid_symptom/:id"
                  name="Covid 19 Symptom"
                  render={(props) => <CovidSymptoms {...props} />}
                />
                <Route
                  exact
                  path="/long_covid/:id"
                  name="Long Covid"
                  render={(props) => <LongCovid {...props} />}
                />
                <Route
                  exact
                  path="/blogs/:lang"
                  name="All Blogs"
                  render={(props) => <Blogs {...props} />}
                />
                <Route
                  exact
                  path="/hospitalinfo/:nice_name/:lang"
                  name="Hospital Information"
                  render={(props) => <HospitalInfo {...props} />}
                />
                <Route
                  exact
                  path="/contact_us"
                  name="Contact Us"
                  render={(props) => <ContactUs {...props} />}
                />
                <Route
                  exact
                  path="/something_went_wrong"
                  name="Something Went Wrong page"
                  render={(props) => <SomethingWentWrong {...props} />}
                />
                <Route
                  exact
                  path="/newaddress"
                  name="Add new Address"
                  // LoadComponent={AddNewAddress}
                  render={(props) => <AddNewAddress {...props} />}

                />
                  <Route
                  exact
                  path="/guest_info"
                  name="Guest Info"
                  // LoadComponent={AddNewAddress}
                  render={(props) => <GuestInfo {...props} />}

                />
                  <Route
                  exact
                  path="/order_confirmation"
                  name="Order Confirmation"
                  // LoadComponent={OrderConfirmation}
                  render={(props) => <OrderConfirmation {...props} />}

                />
                 <Route
                  exact
                  path="/thank_you"
                  name="Thank you page"
                  // LoadComponent={Thankyou}
                  render={(props) => <Thankyou {...props} />}

                />
                 <Route
                  exact
                  path="/user_survey_form/:userID"
                  name="Survey Form"
                  render={(props) => <SurveyForm {...props} />}

                />
                <Route
                  exact
                  path="/user_survey_completed"
                  name="Survey Form"
                  render={(props) => <SurveyFormCompleted {...props} />}
                />
                <Route
                  exact
                  path="/health_packages"
                  name="Health Packages"
                  render={(props) => <HealthPackageSale />}
                />
                 <PublicRoute
                  exact
                  path="/forgot_password"
                  name="Forgot Password Page"
                  LoadComponent={ForgotPassword}
                />
                <PublicRoute
                  exact
                  path="/reset_password"
                  name="Reset Password"
                  LoadComponent={ResetPassword}
                />
                <PublicRoute
                  exact
                  path="/verify_otp"
                  name="OTP Verification"
                  LoadComponent={OtpValidation}
                />
                <PublicRoute
                  exact
                  path="/verify_forgot_password_otp"
                  name="OTP Verification"
                  LoadComponent={ValidateForgotPasswordOTP}
                />
                <PublicRoute
                  exact
                  path="/login"
                  name="User's login"
                  LoadComponent={Login}
                />
                 
                  
                {/* <PublicRoute exact path="/appinfo" name="App INfo" LoadComponent={AppInfo} /> */}
                {/*
                 **************************************************************************************
                 ***** Private Routes Can Only Be Accessed By "AUTHENTICATED" Users *************
                 **************************************************************************************
                 */}
                <PrivateRoute
                  exact
                  path="/logout"
                  name="Logout"
                  LoadComponent={Logout}
                />
                <PrivateRoute
                  exact
                  path="/member_details"
                  name="Member Details"
                  LoadComponent={MemberDetails}
                />
                <PrivateRoute
                  exact
                  path="/medical_history_details"
                  name="Member History"
                  LoadComponent={MedicalHistoryDetails}
                />
                <PrivateRoute
                  exact
                  path="/:clinicNiceName/:doctorNiceName/book_appointment"
                  name="Book Appointment"
                  LoadComponent={BookAppointment}
                />
                <PrivateRoute
                  exact
                  path="/shipping_address"
                  name="Shipping Address"
                  LoadComponent={ShippingAddress}
                />
                <PrivateRoute
                  exact
                  path="/order_confirmation"
                  name="Order Confirmation"
                  LoadComponent={OrderConfirmation}
                />
                <PrivateRoute
                  exact
                  path="/orders"
                  name="Orders"
                  LoadComponent={Orders}
                />
                <PrivateRoute
                  exact
                  path="/order_details"
                  name="Orders"
                  LoadComponent={OrderDetails}
                />
                <PrivateRoute
                  exact
                  path="/thankyou"
                  name="Thank you page"
                  LoadComponent={Thankyou}
                />
                <PrivateRoute
                  exact
                  path="/error"
                  name="Error Page"
                  LoadComponent={Error}
                />
                <PrivateRoute
                  exact
                  path="/reports"
                  name="Health reports"
                  LoadComponent={HealthReport}
                />
                <PrivateRoute
                  exact
                  path="/my_medical_history"
                  name="Health reports"
                  LoadComponent={MedicalHistory}
                />
                <PrivateRoute
                  exact
                  path="/my_forms"
                  name="Health reports"
                  LoadComponent={MyForms}
                />
                <PrivateRoute
                  exact
                  path="/my_vouchers"
                  name="My Vouvhers"
                  LoadComponent={MyVouchers}
                />
                <PrivateRoute
                  exact
                  path="/my_health_packages"
                  name="My Health Packages"
                  LoadComponent={MyHealthPackages}
                />
                <PrivateRoute
                  exact
                  path="/consultations"
                  name="Consultation reports"
                  LoadComponent={Consultations}
                />
                <PrivateRoute
                  exact
                  path="/upcoming_appointments"
                  name="Upcoming Appointments"
                  LoadComponent={UpcomingAppointments}
                />
                <PrivateRoute
                  exact
                  path="/past_appointments"
                  name="Past Appointments"
                  LoadComponent={PastAppointments}
                />
                <PrivateRoute
                  exact
                  path="/myaccount"
                  name="User Profile"
                  LoadComponent={UserProfile}
                />
                <PrivateRoute
                  exact
                  path="/change_password"
                  name="Change Password"
                  LoadComponent={ChangePassword}
                />
                <PrivateRoute
                  exact
                  path="/book_covid_package"
                  name="Book Covid Package"
                  LoadComponent={BookCovidPackage}
                />
                <PrivateRoute
                  exact
                  path="/add_member"
                  name="Add Member"
                  LoadComponent={AddMember}
                />
                <PrivateRoute
                  exact
                  path="/edit_member"
                  name="Edit Member"
                  LoadComponent={EditMember}
                />
                <PrivateRoute
                  exact
                  path="/edit_profile"
                  name="Edit Profile"
                  LoadComponent={EditProfile}
                />
                <PrivateRoute
                  exact
                  path="/upload_medical_history"
                  name="Upload Medical History"
                  LoadComponent={UploadMedicalHistory}
                />
                <PrivateRoute
                  exact
                  path="/upload_documents"
                  name="Upload Dcouments"
                  LoadComponent={UploadDocuments}
                />
                <PrivateRoute
                  exact
                  path="/newcart"
                  name="New Cart Design"
                  LoadComponent={CartPage}
                />
                <PrivateRoute
                  exact
                  path="/covidtest"
                  name="Covid Test Package Booking"
                  LoadComponent={CovidTestingPackage}
                />
              
                <PrivateRoute
                  exact
                  path="/notifications"
                  name="Notifications"
                  LoadComponent={Notifications}
                />
                <PrivateRoute
                  exact
                  path="/editaddress/address/:addressId/city/:cityId/dist/:districtId"
                  name="edit Address"
                  LoadComponent={EditAddress}
                />
                <PrivateRoute
                  exact
                  path="/myaddress"
                  name="List of user's addresses"
                  LoadComponent={MyAddresses}
                />
                <PrivateRoute
                  exact
                  path="/deactivate_account"
                  name="Deactivate user account"
                  LoadComponent={DeActivateAccount}
                />
                <PrivateRoute
                  exact
                  path="/feedback"
                  name="Get user's feedback"
                  LoadComponent={Feedback}
                />
                <PrivateRoute
                  exact
                  path="/wallet"
                  name="user's wallet Info and transaction"
                  LoadComponent={WalletTransaction}
                />
                <PrivateRoute
                  exact
                  path="/book_package/:clinicNiceName"
                  name="Book Health Package"
                  LoadComponent={BookHealthPackage}
                />
                <PrivateRoute
                  exact
                  path="/confirm_health_package_booking_date/:clinicNiceName"
                  name="Book Health Package"
                  LoadComponent={BookHealthPackage}
                />
                <PrivateRoute
                  exact
                  path="/suggestion"
                  name="Suggested Labtest"
                  LoadComponent={Suggestions}
                />
                <PrivateRoute
                  exact
                  path="/corporate/packages"
                  name="Corporate Packages"
                  LoadComponent={CorporatePackages}
                />
                <PrivateRoute
                  exact
                  path="/form1a"
                  name="Form 1A"
                  LoadComponent={form1a}
                />
                <PrivateRoute
                  exact
                  path="/form1b"
                  name="Form 1B"
                  LoadComponent={form1b}
                />
                <PrivateRoute
                  exact
                  path="/medical_history_form"
                  name="Mewdical History Form"
                  LoadComponent={MedicalHistoryForm}
                />
                <Route path="*" component={PageNotFound404} />
              </Switch>
            </LanguageProvider>
          </ThemeProvider>
        </React.Suspense>
      </Router>
      <ToastContainer />
    </>
  );
}
export default App;
