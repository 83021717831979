import { Box, makeStyles, useMediaQuery, useTheme, alpha, Chip, Card, Grid, Dialog, DialogTitle, DialogContent, FormControl, FormHelperText, DialogActions, Button, CircularProgress } from '@material-ui/core'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import Header from '../../components/Header'
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';
import { getClinicPackageTypes, getFeaturedPackageList, getMembersList,getSalePackageList } from '../../request';
import { useLanguage } from '../../hooks/languageContext';
import ThemeLoader from '../../utils/Loader';
import { getSelectedHospitalData, getToken, getUserData } from '../../utils/utils';
import '../../styles/style.css'
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { addHealthPackageToCart } from '../../utils/validation';
import Footer from '../../components/Footer';
import { useLocation } from 'react-router-dom';
import CallOptionComponent from '../../components/CallComponent';
import IndividualHospitalPackageComponent from '../../components/healthPackage/IndividualHospitalPackageListComponent';
import Text from '../../components/typography/TextTypography';
import { SearchComponent } from '../../components/Filter/SearchComponent';
import BookPackageDialogComponent from '../../components/healthPackage/BookPackageDialogComponent';
import _ from 'lodash'
import SomethingWentWrong from '../error/SomethingWentWrong';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import viLocale from 'date-fns/locale/vi';
import DateFnsUtils from '@date-io/date-fns';
import ThemeSelectWithSelect from '../../components/formElements/ThemeSelectWithSelect';
import NewFooter from '../../components/NewFooter';
import HealthPackCard from '../../components/healthPackage/HealthPackCard';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        border: '1px solid grey',
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    /** 
     * ! This sets the inner width of the input search field
     */
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    fullWidthInput: {
        padding: theme.spacing(1, 2, 1, 1),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
}))
/**
 * 
 * @param {packagType} :=> This is individual package type that is shown as a chip
 * @param {setSelectedFilterPackageType} :=> This sets the state of selectedFilter for the package
 * ! @returns :=> Individual Chip for filter
 * ! OnClick Behaviour :=> on clicking the chip, corresponding filter is selected and thus API Call is made to fetch respective packages. 
 */
const FilterChipsUi = ({ packageType, lang, setSelectedFilterPackageType, index, ...rest }) => {
    const label = lang === 'en' ? packageType.lab_service_name : packageType.lab_service_name_vn
    const handleChipSelect = () => {
        setSelectedFilterPackageType(packageType.lab_service_id)
    }
    return (
        <Chip variant="outlined" label={label} onClick={handleChipSelect} {...rest} />
    )
}
const FeaturedHealthPackageList = ({saleLabtest}) => {
    const history = useHistory()
    const { lang, langContent } = useLanguage()
    const [isLoading, setIsLoading] = useState(true)
    const [packageList, setPackageList] = useState([])
    const theme = useTheme()
    const classes = useStyles();
    const matchesUpSM = useMediaQuery(theme.breakpoints.up("sm"));
    const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"))
    const [searchValue, setSearchValue] = useState('')
    const [searchField, setSearchField] = useState('')
    const { register, handleSubmit, errors, setValue } = useForm({ resolver: yupResolver(addHealthPackageToCart) });
    const { handleSubmit: searchSubmit, register: register2 } = useForm();
    const [isAddPackageDialogueOpen, setIsAddPackageDialogueOpen] = useState(false)
    const [selectedFilterPackageType, setSelectedFilterPackageType] = useState(-1) // By default "view all" is Selected 
    const [selectedDate, setSelectedDate] = useState(null)
    const [packageTypes, setPackageTypes] = useState([])
    const token = getToken()
    const userId = getUserData('user_id')
    const userMobile = getUserData('user_mobile')
    const [packageListLoading, setPackageListLoading] = useState(true)
    const [hospital, setHospital] = useState({})
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const clinicId = query.get('clinic')
    const [responseError, setResponseError] = useState(false)
    const [page, setPageNumber] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [packageListNew, setpackageListNew] = useState([]);
    const [loader, setLoader] = useState(false);
    const [featuredPackageList, setFeaturedPackageList] = useState([])

    const observer = useRef();

    const lastBookElementRef = useCallback(node => {
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPage => prevPage + 1)
                setLoader(true)
            }
        })
        if (node) observer.current.observe(node)
    }, [hasMore]);

    useEffect(() => {
        if (packageList.length > 0) {
            setpackageListNew(prevBooks => {
                return [...new Set([...prevBooks, ...packageList])]
            })
        }

    }, [packageList])
    useEffect(() => {
        console.log("SALETSJABDBJKSAKDFSAKJBDHJK",saleLabtest);
    }, [saleLabtest])
    

    /** ************************************************************************************
     * ! This will be called when user performs In-Clinic Package Search
     * @Data :=> Data is the object returned by useForm hook which contains the form input object
     */

    const onSearchSubmit = (data, e) => {
        e.preventDefault()
        const searchField = data.search_field
        setSearchValue(data.searchField)
        setPageNumber(0);
        setpackageListNew([])
        setPackageList([])
        const updates = packageListNew.filter((data) => data.nice_name.includes(searchField));
        setpackageListNew(updates);
    }

    /***
     * ! This Gets called when date is changed
     * 
     */
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setValue('package_date', date, { shouldValidate: true })
    }
    /**
     * ! This is click Handler for changing the filter option
     */
    const handleFilterClick = (packageType) => {
        setSelectedFilterPackageType(packageType.lab_service_id)
    }
    /***************************************************************
     * ! This useEffect will be called on Page Load and whenever the searchValue is changed which will update the rendered list of the health packages
     */

    useEffect(() => {
        setPageNumber(0);
        setpackageListNew([])
        setPackageList([])
        setPackageListLoading(true)

    }, [lang, selectedFilterPackageType])

    useEffect(() => {
        if(saleLabtest=='yes'){
            getSalePackageList(setIsLoading, setFeaturedPackageList, lang,setPackageListLoading)

        }else{
            getFeaturedPackageList(setIsLoading, setFeaturedPackageList, lang,setPackageListLoading)

        }
       
    }, [page, hasMore, lang, selectedFilterPackageType])


    /**
     * ! This is called to clear the searchValue state when the searchField is cleared
     */
    useEffect(() => {
        if (searchField === '') {
            setSearchValue('')
        }
    }, [searchField])
  
    /**
     * ! This useEffect gets called whenever the user changes the filter type for the packages
     * ! selectedFilterPackageType = -1, shows that view all is selected, & if its is then default API is called to render default UI and package list. 
     */
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    

    return (
       
            <React.Fragment>
                <Box ml={2.5} mr={2.5} mb={isLoading ? 4 : 0}>
                    <Box display="flex">
                        <Box width={matchesSMDown ? '100%' : '35%'}>
                            {
                               
                                    // <SearchComponent register={register2} submitHandler={searchSubmit} onSearchSubmit={onSearchSubmit} isLoading={isLoading} />
                            }
                        </Box>
                        <Box>
                        </Box>
                    </Box>
                </Box>
                {
                    isLoading || packageListLoading ? <ThemeLoader /> :
                        (isLoading === false) && (packageListLoading === false) && responseError === true ?
                            <SomethingWentWrong /> :
                            <Box style={{ minHeight: '56vh' }} ml={2.5} mr={2.5}>
                                <Box mt={matchesUpSM ? 1 : 0} display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
                                    {
                                        featuredPackageList && featuredPackageList.length === 0 ?
                                            <Box p={2} component={Card} mt={10}>
                                                <Typography variant="h6" style={{ color: 'red' }}>
                                                    {langContent.sorry_no_packages}
                                                </Typography>
                                            </Box>
                                            :
                                            <Grid container spacing={matchesSMDown ? 0 : 4}>
                                                {
                                                    featuredPackageList && featuredPackageList.map((healthPackage, index) => (
                                                        featuredPackageList.length === index + 1 ? (
                                                            <Grid item key={index} xs={12} sm={12} md={6} lg={4} xl={4}  style={{
                                                                marginBottom: matchesSMDown ?
                                                                    '2rem' : 0
                                                            }}>
                                                                <HealthPackCard key={index} {...{
                                                                    healthPackage, index, langContent, history, setIsAddPackageDialogueOpen, token, setIsLoading,
                                                                    hospital
                                                                }} />
                                                            </Grid>
                                                        ) : (
                                                            <Grid item key={index} xs={12} sm={12} md={6} lg={4} xl={4} style={{
                                                                marginBottom: matchesSMDown ?
                                                                    '2rem' : 0
                                                            }}>
                                                                <HealthPackCard key={index} {...{
                                                                    healthPackage, index, langContent, history, setIsAddPackageDialogueOpen, token, setIsLoading,
                                                                    hospital
                                                                }} />
                                                            </Grid>
                                                        )
                                                    ))
                                                }
                                                {loader && (
                                                    <Grid item md={6} xl={3} lg={4} sm={12} xs={12} justifyContent='center'>
                                                        <ThemeLoader />
                                                    </Grid>
                                                )}
                                            </Grid>
                                    }
                                </Box>
                            </Box>
                }
                
            </React.Fragment>
    )
}
export default FeaturedHealthPackageList

