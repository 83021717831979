import React from 'react';
import { Box } from '@material-ui/core';
import '../../styles/style.css'
import Text from '../typography/TextTypography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useLanguage } from '../../hooks/languageContext';
import AmountFormat from '../AmountFormat';
import { getHealthPackageTimeSlots } from '../../request';
import dashIcon from '../../assets/icons/dashIcons.svg'
import infoIcon from '../../assets/icons/infoIcon.svg'
import reportIcon from '../../assets/icons/report.svg'
import cartIcon from '../../assets/icons/cartIcon.svg'
import _ from 'lodash'
import { Button } from '@material-ui/core';
const IndividualHospitalPackageComponent = ({ healthPackage, memberList, token, userId, history, setIsAddPackageDialogueOpen, setIsLoading, hospital }) => {
    const clinicId = healthPackage.clinic_id
    const { lang, langContent } = useLanguage()

    const addPackageToCart = () => {

        if (!token) {
            history.push('/login')
        } else {
            // localStorage.setItem('healthPackage', JSON.stringify(healthPackage))
            // setIsAddPackageDialogueOpen(true)
            history.push(`/book_package/${hospital.dr_clinic_nice_name}?clinic=${healthPackage.dr_clinic_id}&package=${healthPackage.labtest_id}`)
        }
    }

    return (

        <React.Fragment>
            <Box className='package' width="100%" height="100%" display="flex" flexDirection="column" justifyContent="space-between">
                <Box p={3} >
                    {
                        _.isEmpty(healthPackage) ?
                            null :

                            <Text variant="textsm" color="#02abd6" fontWeight={600}>
                                {lang === 'en' ? healthPackage.item_name_en ? healthPackage.item_name_en : healthPackage.name : healthPackage.item_name_vn ? healthPackage.item_name_vn : healthPackage.name}
                            </Text>
                    }
                    {/* <Box display="flex" mt={2} alignItems="center" >
                        <img src={infoIcon} alt="" style={{ marginLeft: 0 }} />
                        <Text variant="textxs" style={{ marginLeft: '10px' }} fontWeight={500}>
                            No special preparation required
                        </Text>
                    </Box> */}
                    {/* <Box display="flex" mt={2} alignItems="center" >
                        <img src={dashIcon} alt="" />
                        <Text variant="textxs" style={{ marginLeft: '10px' }} fontWeight={500}>
                            26 Parameter(s) Covered
                        </Text>
                    </Box> */}

                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Box>
                            {
                                _.isEmpty(healthPackage.price) ? null :

                                    <Text variant="textsm" fontWeight={600}>
                                        <AmountFormat value={healthPackage.price} />
                                    </Text>
                            }
                        </Box>

                    </Box>

                </Box>

                <Box display="flex" className="bottomRadiusCard">
                    <Box p={2} width="50%" display="flex" justifyContent="center" bgcolor="#2BB2B4" color="primary" style={{ cursor: 'pointer' }} onClick={() => addPackageToCart()}>
                        <Box mr={2}>
                            <img src={cartIcon} alt="" />
                        </Box>
                        <Box mt={.2} >
                            <Text variant="textsm" color='#fff'>

                                {langContent.add_to_cart}
                            </Text>

                        </Box>
                    </Box>
                    <Box width="50%" component={Button} display="flex" alignItems="center" justifyContent="center" bgcolor="rgba(4, 180, 227, 0.22)" style={{ cursor: 'pointer' }} onClick={() => history.push(`/labtest/${healthPackage.nice_name}`)}>
                        <Box >

                            <Text variant="textsm" color="#4F4F4F" fontWeight={500}>
                                {langContent.know_more}
                            </Text>
                        </Box>
                        <Box mt={.2}>
                            <ChevronRightIcon style={{ color: '#fff', fontSize: '2rem' }} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </React.Fragment >
    )
}


export default IndividualHospitalPackageComponent;
