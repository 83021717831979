import { makeStyles, Tab, Tabs, TabScrollButton } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useLanguage } from "../hooks/languageContext";
import {
  loggedInNavigationForDesktop,
  UnAuthenticatedNavigationList,
} from "../static";
const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
  tabContainer: {
    marginLeft: "auto",
  },

  appbarBackground: {
    backgroundColor: "transparent",
    [theme.breakpoints.down("xs")]: {
      justify: "center",
      align: "center",
      alignItems: "center",
    },
  },
  tab: {
    fontSize: "16px",
    minWidth: 10,
  },
}));
const HeaderNavTabs = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
   const query = new URLSearchParams(location.search)
    const clinicId = query.get('clinic')
    const packageId = query.get('package')
  const [value, setValue] = useState(0);
  const { lang, langContent } = useLanguage();
  const handleChange = (event, value) => {
    setValue(value);
  };
  const MyTabScrollButton = withStyles((theme) => ({
    root: {
      width: 28,

      transition: "width 0.5s",
      "&.Mui-disabled": {
        width: 0,
      },
    },
  }))(TabScrollButton);
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }


  useEffect(() => {
    if (window.location.hash === "/" && value !== 0) {
      setValue(0);
    } else if (
      (window.location.hash === "#/myaccount" ||
        window.location.hash === "#/members" ||
        window.location.hash === "#/consultations" ||
        window.location.hash === "#/reports" ||
        window.location.hash === "#/orders" ||
        window.location.hash === "#/wallet" ||
        window.location.hash === "#/suggestion" ||
        window.location.hash === "#/corporate/packages" ||
        window.location.hash === `#/book_package/demo-corporate-two?clinic=${clinicId}&package=${packageId}` ||
        window.location.hash === "#/myaddress") &&
      value !== 1
    ) {
      setValue(1);
    } else if (
      (window.location.hash === "#/upcoming_appointments" ||
        window.location.hash === "#/past_appointments") &&
      value !== 2
    ) {
      setValue(2);
    } else if (window.location.hash === "#/hospitals" && value !== 3) {
      setValue(3);
    } else if (window.location.hash === "#/health_packages" && value !== 4) {
      setValue(4);
    } else if (window.location.hash === "#/about_us" && value !== 5) {
      setValue(5);
    }
  }, [value]);
  return (
    <Tabs
      className={classes.tabContainer}
      value={value}
      onChange={handleChange}
      variant="scrollable"
      ScrollButtonComponent={MyTabScrollButton}
      scrollButtons="on"
    >
      {loggedInNavigationForDesktop.map((nav, index) => {
        // console.log("route", nav.route)
        return (
          <Tab
            label={langContent[nav.linkName]}
            component={Link}
            to={nav.route}
            className={classes.tab}
            disableRipple
            {...a11yProps(value)}
            key={index}
          />
        );
      })}
    </Tabs>
  );
};
export default HeaderNavTabs;

export const HeaderNavTabsUnAuthorized = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { lang, langContent } = useLanguage();
  const handleChange = (event, value) => {
    setValue(value);
  };
  const MyTabScrollButton = withStyles((theme) => ({
    root: {
      width: 28,
      transition: "width 0.5s",
      "&.Mui-disabled": {
        width: 0,
      },
    },
  }))(TabScrollButton);
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (window.location.hash === "#/" && value !== 0) {
      setValue(0);
    } else if (window.location.hash === "#/login" && value !== 1) {
      setValue(1);
    } else if (window.location.hash === "#/hospitals" && value !== 2) {
      setValue(2);
    } else if (window.location.hash === "#/products" && value !== 3) {
      setValue(3);
    } else if (window.location.hash === "#/about_us" && value !== 5) {
      setValue(5);
    }
  }, [value]);
  return (
    <Tabs
      className={classes.tabContainer}
      value={value}
      onChange={handleChange}
      variant="scrollable"
      ScrollButtonComponent={MyTabScrollButton}
      scrollButtons="on"
    >
      {UnAuthenticatedNavigationList.map((nav, index) => {
          return (
            <Tab
              label={langContent[nav.linkName]}
              component={Link}
              to={nav.route}
              className={classes.tab}
              disableRipple
              {...a11yProps(value)}
              key={index}
            />
          );
      })}
    </Tabs>
  );
};
