import { FormHelperText, TextField } from '@material-ui/core';
import React from 'react'

const ThemeInput = ({
    name, register, errors, isRequired, label, shrinkStatus = true, variant = "outlined",defaultValue='', ...rest
}) => {

    return (
        <>
            <TextField
                label={label}
                required={isRequired}
                name={name}
                inputRef={register}
                defaultValue={defaultValue}
                variant={variant}
                {...rest}
                error={Boolean(errors[name])}
                InputLabelProps={{ shrink: shrinkStatus ? true : undefined }}
            />
            {errors[name] ? <div style={{ display: 'flex', justify: 'flex-start', color: 'tomato', marginTop: '.5rem', textTransform: 'capitalize' }}>
                {/* <small>{errors[name]?.message}</small> */}
                <FormHelperText style={{ color: 'tomato' }}>{errors[name]?.message}</FormHelperText>
            </div> : ''}

        </>
    )
}

export default ThemeInput;

// import { FormHelperText, TextField } from '@material-ui/core';
// import React from 'react';

// const ThemeInput = ({
//   name,
//   register,
//   errors,
//   isRequired = false,
//   label,
//   shrinkStatus = true,
//   variant = "outlined",
//   defaultValue = '',
//   watch,
//   ...rest
// }) => {
//   return (
//     <>
//       <TextField
//         watch={watch}
//         label={label}
//         required={isRequired}
//         name={name}
//         inputRef={register({ required: isRequired })}
//         defaultValue={defaultValue} 
//         variant={variant}
//         // error={Boolean(errors[name])} 
//         InputLabelProps={{ shrink: shrinkStatus ? true : undefined }}
//         {...rest}  
//       />
//       {errors[name] && (
//         <FormHelperText style={{ color: 'tomato', marginTop: '.5rem' }}>
//           {errors[name]?.message}
//         </FormHelperText>
//       )}
//     </>
//   );
// };

// export default ThemeInput;
