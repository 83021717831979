import React from 'react'
import Header from '../../components/Header'
import NewFooter from '../../components/NewFooter'
import { Container, Typography,Box, useMediaQuery,useTheme } from '@material-ui/core';
import FeaturedHealthPackageList from '../healthPackage/FeaturedHealhPackageList';
import { useLanguage } from '../../hooks/languageContext';


function HealthPackageSale() {

    const theme = useTheme()
    const { langContent } = useLanguage()
    const matchesSMDown = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            <Header/>
            <Container maxWidth="lg">
                <Box display="flex" justifyContent="center" mt={matchesSMDown ? 4 : 10} mb={6}>
                    <Typography variant="h1" >
                        {langContent.health_packages}
                    </Typography>
                </Box>
                <FeaturedHealthPackageList saleLabtest="yes" />
            </Container>
            <NewFooter />
        </>
    )
}

export default HealthPackageSale
