import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core';
import React from 'react';
import { useLanguage } from '../../hooks/languageContext';
const ThemeSelectWithSelect = ({ name, label, errors, isRequired, value = '', disableUnderline = false, variant = "outlined", valueKey = '', labelKey = '', register, optionObj = [],defaultValue='', ...rest }) => {
    const { langContent } = useLanguage()
    return (
        <>
            <FormControl variant={variant}  {...rest}>
                <InputLabel htmlFor="outlined-age-native-simple" shrink={true}>{label}</InputLabel>
                <Select
                    required={isRequired}
                    native
                    notched="true"
                    defaultValue={defaultValue}
                    // value={value? value:''}
                    error={Boolean(errors[name])}
                    name={name}
                    label={label}
                    inputProps={{
                        inputRef: register,
                    }}
                    // disableUnderline={disableUnderline}
                >
                    <option value="" key={null}>
                        {langContent.select}
                    </option>
                    {
                        optionObj.length && optionObj.map((obj, key) => (<option value={valueKey ? obj[valueKey] : obj.key} key={key}>{labelKey ? obj[labelKey] : obj.label}</option>))
                    }
                </Select>
            </FormControl>
            {errors[name] ? <div style={{ display: 'flex', justify: 'flex-start', color: 'tomato', marginTop: '.5rem', textTransform: 'capitalize' }}>
                <FormHelperText style={{ color: 'tomato' }}>{errors[name]?.message}</FormHelperText>
            </div> : ''}
        </>
    )
}
export default ThemeSelectWithSelect