import React, { useState } from 'react';
import { Box, Card, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import '../../styles/style.css'
import Text from '../typography/TextTypography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useLanguage } from '../../hooks/languageContext';
import AmountFormat from '../AmountFormat';
import { addDiagHealthPackage, addHealthPackage } from '../../request';
import dashIcon from '../../assets/icons/dashIcons.svg'
import infoIcon from '../../assets/icons/infoIcon.svg'
import reportIcon from '../../assets/icons/report.svg'
import cartIcon from '../../assets/icons/cartIcon.svg'
import _ from 'lodash'
import { Button } from '@material-ui/core';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import DialogContentText from '@mui/material/DialogContentText';
import { Badge, IconButton } from '@mui/material';
import SaleLabel from "../../assets/img/sale label.png";
import AddIcon from '@mui/icons-material/Add';
import { useHistory, useLocation } from 'react-router';
import { getUserData } from '../../utils/utils';


// const HealthPackCard1 = ({ healthPackage, memberList, token, userId, history, setIsAddPackageDialogueOpen, setIsLoading, hospital }) => {
//     const clinicId = healthPackage.clinic_id
//     const { lang, langContent } = useLanguage()
//     const addPackageToCart = () => {

//         if (!token) {
//             history.push('/login')
//         } else {
//             // localStorage.setItem('healthPackage', JSON.stringify(healthPackage))
//             // setIsAddPackageDialogueOpen(true)
//             history.push(`/book_package/${hospital.dr_clinic_nice_name}?clinic=${healthPackage.dr_clinic_id}&package=${healthPackage.labtest_id}`)

//         }
//     }

//     return (

//         <React.Fragment>
//             <Box className='package' width="100%" height="100%" display="flex" flexDirection="column" justifyContent="space-between">
//                 <Box p={3} >
//                     {
//                         _.isEmpty(healthPackage) ?
//                             null :

//                             <Text variant="textsm" color="#02abd6" fontWeight={600}>
//                                 {lang === 'en' ? healthPackage.item_name_en ? healthPackage.item_name_en : healthPackage.name : healthPackage.item_name_vn ? healthPackage.item_name_vn : healthPackage.name}
//                             </Text>
//                     }
//                     <Box mt={2} display="flex" justifyContent="space-between">
//                         <Box>
//                             {
//                                 _.isEmpty(healthPackage.price) ? null :

//                                     <Text variant="textsm" fontWeight={600}>
//                                         <AmountFormat value={healthPackage.price} />
//                                     </Text>
//                             }
//                         </Box>

//                     </Box>

//                 </Box>

//                 <Box display="flex" className="bottomRadiusCard">
//                     <Box p={2} width="50%" display="flex" justifyContent="center" bgcolor="#2BB2B4" color="primary" style={{ cursor: 'pointer' }} onClick={() => addPackageToCart()}>
//                         <Box mr={2}>
//                             <img src={cartIcon} alt="" />
//                         </Box>
//                         <Box mt={.2} >
//                             <Text variant="textsm" color='#fff'>

//                                 {langContent.add_to_cart}
//                             </Text>

//                         </Box>
//                     </Box>
//                     <Box width="50%" component={Button} display="flex" alignItems="center" justifyContent="center" bgcolor="rgba(4, 180, 227, 0.22)" style={{ cursor: 'pointer' }} onClick={() => history.push(`/labtest/${healthPackage.nice_name}`)}>
//                         <Box >

//                             <Text variant="textsm" color="#4F4F4F" fontWeight={500}>
//                                 {langContent.know_more}
//                             </Text>
//                         </Box>
//                         <Box mt={.2}>
//                             <ChevronRightIcon style={{ color: '#fff', fontSize: '2rem' }} />
//                         </Box>
//                     </Box>
//                 </Box>
//             </Box>
//         </React.Fragment >
//     )
// }


// export default HealthPackCard1;

const HealthPackCard = ({ healthPackage, memberList, history, setIsAddPackageDialogueOpen, setIsLoading, hospital }) => {
    const addNoImage = (ev) => {
        ev.target.src = 'https://fact.co.in/images/NoImage.png';
    }
    const { lang, langContent } = useLanguage()
    const [isErrModalOpen, setIsErrModalOpen] = useState(false)
    const [requestPending, setRequestPending] = useState(false)
    const [ErrorMessage, setErrorMessage] = useState('')
    const [cartMessage, setCartMessage] = useState('')
    const [ismodalOpen, setIsModalOpen] = useState(false)
    const [deleteCart, setDeleteCart] = useState('no')
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const corporateId = query.get('corporate')
    const userId = getUserData('user_id')
    const memberName = query.get('member')

    const token = localStorage.getItem('accessToken')
    


    const handleCloseModal = () => {
        setIsErrModalOpen(false);
    };


    // const [selectedPackageDate, setSelectedPackageDate] = useState(null)

    // const formattedDate = moment(selectedPackageDate, 'DD/MM/YYYY').format("YYYY-MM-DD")

    const addPackageToCart = (healthPackage) => {
        console.log(healthPackage)

        if (!token) {
            history.push('/login')
        }
        else {
            // localStorage.setItem('healthPackage', JSON.stringify(healthPackage))
            // setIsAddPackageDialogueOpen(true)
            // history.push(`/book_package/${hospital.dr_clinic_nice_name}?clinic=${healthPackage.dr_clinic_id}&package=${healthPackage.labtest_id}`)
            if (corporateId == null) {
                // addHealthPackage(clinicId, lang, selectedPackage, userId, data.sample_collect_time, formattedDate, data.member_id, token, history, langContent, setRequestPending)
                // const item_type = 'labtest'
                addHealthPackage(healthPackage.dr_clinic_id, lang, healthPackage.labtest_id, userId, '', null, null, 0, token, history, langContent, setIsAddPackageDialogueOpen, deleteCart, setIsModalOpen, setRequestPending, setCartMessage, setErrorMessage, setIsErrModalOpen)
            }
    }
}

    return (
        <Card style={{ borderRadius: '8px', boxShadow: 5, overflow: 'hidden', position: 'relative' }}>
            {/* Sale Badge */}
            <img src={SaleLabel} alt="" style={{ position: 'absolute', top: 0, left: 1, zIndex: 1, width: '42px' }} />

            {/* Image */}
            <CardMedia
                component="img"
                image={healthPackage.imageurl}
                alt="Product Image"
                onError={ev => { addNoImage(ev) }}
                style={{ height: 240, objectFit: 'cover' }}
            />

            {/* Content */}
            <CardContent sx={{ p: 2 }}>
                <Typography variant="h6" noWrap component="div" style={{ fontWeight: '600' }} sx={{ mb: 1 }}>
                    {healthPackage.name}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', mb: 1 }}>
                    <div>

                    </div>
                    <div style={{ textAlign: 'end' }}>
                        <Typography variant="body2" color="text.secondary" style={{ textDecoration: 'line-through' }}>
                            {healthPackage.price}đ
                        </Typography>
                        <Typography variant="h5" color="error" fontWeight="bold">
                            {healthPackage.selling_price}đ
                        </Typography>
                    </div>

                </Box>
                <Button variant="text" style={{ float: 'right' }} sx={{ p: 0, color: 'text.secondary', textTransform: 'none', }} onClick={() => history.push(`/labtest/${healthPackage.nice_name}`)}>
                    More Details <ChevronRightIcon />
                </Button>
            </CardContent>

            {/* Add to Cart Button */}
            <Box style={{ position: 'absolute', bottom: 0, left: 0, padding: '0.8em', backgroundColor: 'teal', borderTopRightRadius: '8px' }} onClick={() => addPackageToCart(healthPackage)}>
                <IconButton style={{ color: 'white', padding: '0px' }}>
                    <ShoppingBagOutlinedIcon />
                    <AddIcon style={{ height: '0.7em', marginLeft: '-3px' }} />
                </IconButton>
            </Box>
        </Card>
    );
};
export default HealthPackCard;


