import { Box, Chip, Container, makeStyles, Tab, Tabs, withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useLanguage } from '../hooks/languageContext';
import TabScrollButton from '@material-ui/core/TabScrollButton';
import { getUserData, SHOW_TOAST_MESSAGE, SHOW_TOAST_MESSAGE_SUCCESS } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    toolbarMargin: {
        ...theme.mixins.toolbar
    },
    tabContainer: {
        marginLeft: 'auto'
    },
    appbarBackground: {
        backgroundColor: 'transparent',
        [theme.breakpoints.down('xs')]: {
            justify: "center",
            align: "center",
            alignItems: 'center'
        }
    },
    tab: {
        // color: 'purple',
        // fontFamily: 'Lato',
        // fontWeight: 700,
        // textTransform: 'none',
        fontSize: '16px',
        minWidth: 10
        // [theme.breakpoints.down('xs')]:{
        //     marginTop:'1em',
        //     fontSize:'1.5rem',
        //     fontWeight:400
        // }
    }
}));

export const MobileSubNavigation = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0)
    const corporateHospital_id = getUserData('hospital_id')
    const isCorporateUser = corporateHospital_id ? true : false
    const { langContent } = useLanguage()
    const handleChange = (event, value) => {
        setValue(value);
    }
    const MyTabScrollButton = withStyles(theme => ({
        root: {
            width: 28,
            transition: 'width 0.5s',
            '&.Mui-disabled': {
                width: 0,
            },
        },
    }))(TabScrollButton);
    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }
    useEffect(() => {
        if (window.location.hash === '#/myaccount' && value !== 0) {
            setValue(0)
        } else if (window.location.hash === '#/members?view=members' && value !== 1) {
            setValue(1)
        }
        else if (window.location.hash === '#/members?view=medical_history' && value !== 2) {
            setValue(2)
        }
        else if ((window.location.hash === '#/members?view=forms' || window.location.hash === '#/my_forms') && value !== 3) {
            setValue(3)
        }
        else if (window.location.hash === '#/orders' && value !== 4) {
            setValue(4)
        }
        // else if (window.location.hash === '#/my_vouchers' && value !== 5) {
        //     setValue(5)
        // }
        else if (window.location.hash === '#/my_health_packages' && value !== 5) {
            setValue(5)
        }
        else if (window.location.hash === '#/corporate/packages' && value !== 6 && isCorporateUser) {
            setValue(6)
        }
    }, [value])
    return (
        <Box mt={4}>
            <Tabs className={classes.tabContainer} value={value} onChange={handleChange} variant="scrollable" ScrollButtonComponent={MyTabScrollButton} scrollButtons="on">
                <Tab label={langContent.profile} component={Link} to="/myaccount" className={classes.tab} disableRipple {...a11yProps(value)} />
                <Tab label={langContent.members} component={Link} to="/members?view=members" className={classes.tab} disableRipple {...a11yProps(value)} />
                <Tab label={langContent.my_documents} component={Link} to="/members?view=medical_history" className={classes.tab} disableRipple {...a11yProps(value)} />
                <Tab label={langContent.my_forms} component={Link} to="/members?view=forms" className={classes.tab} disableRipple {...a11yProps(value)} />
                <Tab label={langContent.my_orders} component={Link} to="/orders" className={classes.tab} disableRipple {...a11yProps(value)} />
                {/* <Tab label={langContent.my_vouchers} component={Link} to="/my_vouchers" className={classes.tab} disableRipple {...a11yProps(value)} /> */}
                <Tab label={langContent.my_health_packages} component={Link} to="/my_health_packages" className={classes.tab} disableRipple {...a11yProps(value)} />
                {isCorporateUser ?
                    <Tab label={langContent.corporate_packages} component={Link}
                        to="/corporate/packages" className={classes.tab} disableRipple {...a11yProps(value)} />
                    : null
                }
            </Tabs>
        </Box>
    )
}
/**
 * 
 * @returns Mobile subnavigation 
 */
export const DesktopSubNav = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0)
    const { langContent } = useLanguage()
    const history = useHistory()
    const corporateHospital_id = getUserData('hospital_id')
    const isCorporateUser = corporateHospital_id ? true : false
    const menuOptions = [
        { name: 'my_account', link: '/myaccount' },
        { name: 'members', link: '/members?view=members' },
        { name: 'my_orders', link: '/orders' },
        { name: 'my_documents', link: '/members?view=medical_history' },
        { name: 'my_forms', link: '/members?view=forms' },
        // { name: 'my_vouchers', link: '/my_vouchers' },
        { name: 'my_health_packages', link: '/my_health_packages' }

    ]
    if (isCorporateUser) {
        menuOptions.push({ name: 'corporate_packages', link: '/corporate/packages' })
    }
    const handleClick = (nav, index) => {
        if (nav.name === 'medical_report') {
            let UserDetails = JSON.parse(localStorage.getItem('userDetails'));
            let ReportUrl = UserDetails.patient_report_url;
            if (ReportUrl !== '') {
                window.open(ReportUrl, "_blank")
            } else {
                SHOW_TOAST_MESSAGE_SUCCESS("No Report URL found");
            }
        } else {
            setValue(index)
            history.push(nav.link)
        }
    }

    useEffect(() => {
        if (window.location.hash === '#/myaccount' && value !== 0) {
            setValue(0)
        } else if (window.location.hash === '#/members?view=members' && value !== 1) {
            setValue(1)
        }
        else if (window.location.hash === '#/orders' && value !== 2) {
            setValue(2)
        }
        else if (window.location.hash === '#/members?view=medical_history' && value !== 3) {
            setValue(3)
        }
        else if ((window.location.hash === '#/members?view=forms' || window.location.hash === '#/my_forms') && value !== 4) {
            setValue(4)
        }
        else if (window.location.hash === '#/my_health_packages' && value !== 5) {
            setValue(5)
        }
        else if (window.location.hash === '#/myaddress' && value !== 6) {
            setValue(6)
        }
        else if (window.location.hash === '#/suggestion' && value !== 7) {
            setValue(7)
        }
        else if (window.location.hash === '#/corporate/packages' && value !== 8 && isCorporateUser) {
            setValue(8)
        }
    }, [value])
    return (
        <Box className={classes.root} mt={4}>
            {
                menuOptions.map((nav, index) => (
                    <Chip
                        variant="outlined"
                        size="small"
                        label={langContent[nav.name]}
                        style={{ marginRight: '10px', fontSize: '14px' }}
                        clickable
                        onClick={() => handleClick(nav, index)}
                        key={index}
                        color={index === value ? 'secondary' : 'default'}
                    />
                ))
            }
        </Box>
    )
}