import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleIcon from '@material-ui/icons/People';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { faHandshake } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PostAddIcon from '@material-ui/icons/PostAdd';
/**
 *  ! Return Array of Logged In navigation Menu
 */
export const loggedInNavigation =
    [{
        linkName: 'home',
        route: '/',
        Icon: HomeIcon

    },
    {
        linkName: 'my_account',
        route: '/myaccount',
        Icon: AccountCircleIcon

    },
    {
        linkName: 'members',
        route: '/members?view=members',
        Icon: PeopleIcon
    },
    {
        linkName: 'upcoming_appointments',
        route: '/upcoming_appointments',
        Icon: PermContactCalendarIcon
    },
    {
        linkName: 'past_appointments',
        route: '/past_appointments',
        Icon: CalendarTodayIcon
    },
    // {
    //     linkName: 'reports',
    //     route: '/reports',
    //     Icon: ReceiptIcon
    // },
    {
        linkName: 'my_orders',
        route: '/orders',
        Icon: ListAltIcon
    },
 
    {
        linkName: 'clinic_list_',
        route: '/hospitals',
        Icon: LocalHospitalIcon
    },
    // {
    //     linkName: 'my_address',
    //     route: '/myaddress',
    //     Icon: PostAddIcon
    // },
    {
        linkName: 'logout',
        route: '/logout',
        Icon: ExitToAppIcon
    } 
    ]


export const loggedInNavigationForDesktop =
    [{
        linkName: 'home',
        route: '/',
        Icon: AccountCircleIcon

    },
    {
        linkName: 'my_account',
        route: '/myaccount',
        Icon: AccountCircleIcon

    },

    {
        linkName: 'appointments',
        route: '/upcoming_appointments',
        Icon: PermContactCalendarIcon
    },
    {
        linkName: 'clinic_list_',
        route: '/hospitals',
        Icon: LocalHospitalIcon
    },
    // {
    //     linkName: 'Vouchers',
    //     route: '/products',
    //     Icon: LocalHospitalIcon
    // },
    {
        linkName: 'health_packages',
        route: '/health_packages',
        Icon: LocalHospitalIcon
    },
    ]


export const UnAuthenticatedNavigationList = [
    {
        linkName: 'home',
        route: '/',
        Icon: HomeIcon
    },
    {
        linkName: 'login_register',
        route: '/login',
        Icon: ExitToAppIcon
    },
    // {
    //     linkName: 'doctor_login',
    //     route: '/doctor_login',
    //     Icon: ExitToAppIcon
    // },
    {
        linkName: 'clinic_list_',
        route: '/hospitals',
        Icon: LocalHospitalIcon
    },
    // {
    //     linkName: 'Vouchers',
    //     route: '/products',
    //     Icon: LocalHospitalIcon
    // },
    {
        linkName: 'health_packages',
        route: '/health_packages',
        Icon: LocalHospitalIcon
    },
    // {
    //     linkName: 'partner_with_us',
    //     route: '/partner_with_us',
    //     Icon: LocalHospitalIcon
    // },

    // {
    //     linkName: 'about_us',
    //     route: '/about_us',
    //     Icon: ExitToAppIcon
    // }

]


export const UnAuthenticatedNavigationListForDesktop = [
    {
        linkName: 'home',
        route: '/',
        Icon: AccountCircleIcon

    },
    {
        linkName: 'login_register',
        route: '/login',
        Icon: ExitToAppIcon
    },
    // {
    //     linkName: 'doctor_login',
    //     route: '/doctor_login',
    //     Icon: ExitToAppIcon
    // },
    {
        linkName: 'clinic_list_',
        route: '/hospitals',
        Icon: LocalHospitalIcon
    },
    // {
    //     linkName: 'partner_with_us',
    //     route: '/partner_with_us',
    //     Icon: LocalHospitalIcon
    // },

    // {
    //     linkName: 'about_us',
    //     route: '/about_us',
    //     Icon: ExitToAppIcon
    // }

]

export const optionGender = [
    {
        key: 'M',
        label: 'Male'
    },
    {
        key: 'F',
        label: 'Female'
    },
    {
        key: 'T',
        label: 'Others'
    }
]

export const optionGenderVn = [


    {
        key: 'M',
        label: 'Nam'
    },
    {
        key: 'F',
        label: 'Nữ'
    },
    {
        key: 'T',
        label: 'Khác'
    }

]

export const optionMarital = [
    {
        key: 'Married',
        label: 'Married'
    },
    {
        key: 'Single',
        label: 'Single'
    },
    {
        key: 'Divorced',
        label: 'Divorced'
    }
]


export const optionMaritalVn = [
    {
        key: 'Married',
        label: 'Đã kết hôn'

    },

    {
        key: 'Single',
        label: 'Độc thân'

    },

    {
        key: 'Divorced',

        label: 'Đã ly hôn'
    }


]

export const yearListEn = (lang) => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const yearArray = []
    for (let i = 1940; i <= currentYear; i++) {
        const obj = {
            key: i,
            label: i
        }
        yearArray.push(obj)
    }
    const dayArray = []
    for (let j = 1; j <= 31; j++) {
        let day = ''
        if (j < 10) {
            day = '0' + j
        } else {
            day = j
        }
        const obj = {
            key: day,
            label: day
        }
        dayArray.push(obj)

    }
    const monthArray = []
    const monthEn = [
        '',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const monthVn = [
        '',
        'Tháng một',
        'Tháng hai',
        'Tháng ba',
        'Tháng tư',
        'Tháng năm',
        'Tháng sáu',
        'Tháng bảy',
        'Tháng tám',
        'Tháng chín',
        'Tháng mười',
        'Tháng mười một',
        'Tháng mười hai'
    ]
    for (let i = 1; i <= 12; i++) {
        let month = ''
        if (i < 10) {
            month = '0' + i
        } else {
            month = i
        }
        const obj = {
            key: month,
            label: lang === 'en' ? monthEn[i] : monthVn[i]

        }
        monthArray.push(obj)
    }
    return {
        month: monthArray,
        day: dayArray,
        year: yearArray

    }
}
export const appVersion = '1.0.24';
/**
 * ! This API Details for CometChat Application
 */
export const AppIDCometChat = '200850303156b423';
export const RegionCometChat = 'us';
export const AuthKeyCometChat = 'a72c13a1092b3b21e840ddb1d484e59a5b536038';
